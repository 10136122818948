import React, { useEffect, useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import useInfo from "../../customhooks/user/useinfo";
import {
    Avatar,
    IconButton,
    Tooltip,
} from "@mui/material";

import PersonIcon from '@mui/icons-material/Person';
/*import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InsertCommentIcon from '@mui/icons-material/InsertComment';*/
import { ChatBubbleOutlineOutlined } from "@mui/icons-material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SpeedIcon from '@mui/icons-material/Speed';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import { deleteLoginState } from "../../redux/actions/loginaction";
import { encryptStorage } from "../../utils/storage";

export default function Profile(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [isAdmin, setAdmin] = useState(false);
    const userInfo = useInfo();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const logout = () => {
        if (isAdmin) {
            encryptStorage.removeItem('admin-info');
            navigate('admin/login');
        } else {
            encryptStorage.removeItem('user-info');
            navigate('/');
        }

        deleteLoginState();

    }

    useEffect(() => {
        if (props.userInfo) {
            let info = props.userInfo;
            setFullName(info.firstName + ' ' + info.lastName);
            setAdmin(false);
        } else {
            setAdmin(true);
        }
    }, [props])

    const openChatWithUs = () => {
        window.open('https://wa.me/919090951010', '_blank')
    }

    return (
        <>
            <Tooltip>
                <IconButton variant="contained" onClick={handleClick}>
                    {
                        userInfo?.profilePic !== '' ? <Avatar src={`${userInfo?.profilePic}`} /> : <PersonIcon />
                    }
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                {
                    !isAdmin &&
                    <>
                        <MenuItem onClick={handleClose}>
                            {fullName}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => navigate('/profile')}>
                            <ListItemIcon>
                                <PersonIcon fontSize="small" />
                            </ListItemIcon>
                            Profile
                        </MenuItem>
                        <MenuItem onClick={openChatWithUs}>
                            <ListItemIcon>
                                <ChatBubbleOutlineOutlined fontSize="small" />
                            </ListItemIcon>
                            Chat With Us
                        </MenuItem>
                        {/*} <MenuItem onClick={() => navigate('/address')}>
                            <ListItemIcon>
                                <LocationOnIcon fontSize="small" />
                            </ListItemIcon>
                            My Address
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <ShoppingCartIcon fontSize="small" />
                            </ListItemIcon>
                            My Cart
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <CalendarMonthIcon fontSize="small" />
                            </ListItemIcon>
                            My Appointment
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <InsertCommentIcon fontSize="small" />
                            </ListItemIcon>
                            My Request
                        </MenuItem>
                */}
                    </>
                }

                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>

            </Menu>
        </>
    )
}