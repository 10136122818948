import Swal from "sweetalert2";
import getUserInfo from "../customhooks/user/useinfo";

class Utils {
    //Getting API description based on environment
    static getAPIDescription(signature) {
        let isLocal = window.location.href.indexOf('localhost') !== -1;
        let apiGateWay = '';
        if (isLocal) {
            //apiGateWay = 'http://localhost:3001/api';
            apiGateWay = 'https://pricealgo.techsquadrons.com/api';
        } else {
            apiGateWay = '/api';
        }

        switch (signature) {
            case 'log-in':
                return apiGateWay + '/login'
                break;
            case 'get-general-data-set':
                return apiGateWay + '/getgeneraldataset'
                break;
            case 'sign-up':
                return apiGateWay + '/signup'
                break;
            case 'fetch-notification':
                return apiGateWay + '/fetchnotification'
                break;
            case 'push-notification':
                return apiGateWay + '/pushnotification'
                break;
            case 'hide-notification':
                return apiGateWay + '/hidenotification'
                break;
            case 'upload-profile-pic':
                return apiGateWay + '/profilepicuploadtospace'
                break;
            case 'upload-event-pic':
                return apiGateWay + '/eventpicuploadtospace'
                break;
            case 'upload-member-pic':
                return apiGateWay + '/memberpicuploadtospace'
                break;
            case 'upload-hsc-certificate':
                return apiGateWay + '/hsccertificateuploadtospace'
                break;
            case 'upload-degree-certificate':
                return apiGateWay + '/degreecertificateuploadtospace'
                break;
            case 'upload-twelfth-marksheet':
                return apiGateWay + '/twelfthmarksheetuploadtospace'
                break;
            case 'upload-provisional-llb':
                return apiGateWay + '/provisionalllbuploadtospace'
                break;
            case 'upload-conduct-certificate':
                return apiGateWay + '/conductcertificateuploadtospace'
                break;
            case 'upload-cast-certificate':
                return apiGateWay + '/castcertificateuploadtospace'
                break;
            case 'upload-conviction-doc':
                return apiGateWay + '/convictiondocuploadtospace'
                break;
            case 'upload-passport-photo':
                return apiGateWay + '/passportphotouploadtospace'
                break;
            case 'upload-relieve-order':
                return apiGateWay + '/relieveorderuploadtospace'
                break;
            case 'update-profile':
                return apiGateWay + '/updateprofile'
                break;
            case 'search-applicant':
                return apiGateWay + '/searchapplicant'
                break;
            case 'search-applicant-new':
                return apiGateWay + '/searchApplicantNew'
                break;
            case 'get-profile':
                return apiGateWay + '/getProfile'
                break;
            case 'update-application-info':
                return apiGateWay + '/updateapplicationinfo'
                break;
            case 'contact-us':
                return apiGateWay + '/contactUs'
                break;
            case 'submit-enrollment':
                return apiGateWay + '/submitenrollment'
                break;
            case 'user-list':
                return apiGateWay + '/getUserList'
                break;
            case 'applicant-list':
                return apiGateWay + '/getapplicantlist'
                break;
            case 'update-applicant':
                return apiGateWay + '/updateapplicant'
                break;
            case 'create-order':
                return apiGateWay + '/createorder'
                break;
            case 'send-status-email':
                return apiGateWay + '/sendstatusemail'
                break;
            case 'fetch-application-status':
                return apiGateWay + '/fetchapplicationstatus'
                break;
            case 'fetch-certificate-info':
                return apiGateWay + '/fetchcertificateinfo'
                break;
            case 'scholarship-list':
                return apiGateWay + '/getScholarshipList'
                break;
            case 'enrollment-list':
                return apiGateWay + '/getEnrollmentList'
                break;
            case 'delete-applicant-details':
                return apiGateWay + '/deleteapplicantdetails'
                break;
            case 'admin-log-in':
                return apiGateWay + '/adminLogIn'
                break;
            case 'application-status-chart':
                return apiGateWay + '/getApplicationStatusChart'
            case 'user_reference_list':
                return apiGateWay + '/user_reference_list'
            case 'create-event':
                return apiGateWay + '/createevent'
            case 'get-event-list':
                return apiGateWay + '/geteventlist'
                break;
            case 'delete-event':
                return apiGateWay + '/deleteevent'
                break;
            case 'get-event-tiles':
                return apiGateWay + '/geteventtiles'
                break;
            case 'add-member':
                return apiGateWay + '/addmember'
            case 'get-member-list':
                return apiGateWay + '/getmemberlist'
                break;
            case 'get-member-tiles':
                return apiGateWay + '/getmembertiles'
                break;
            case 'delete-member':
                return apiGateWay + '/deletemember'
                break;
            case 'save-user-data':
                return apiGateWay + '/enrollment/save';
            case 'get-user-enrollment':
                return apiGateWay + '/get_user_enrollment';
            case 'get-upload-token':
                return apiGateWay + '/getUploadToken';
            case 'get-applicant-detail':
                return apiGateWay + '/getApplicantDetails'
            default: 
            // code block
        }
    }

    static getUserType = () => {
        if (window.location.href.indexOf('/admin') !== -1) {
            return 'admin';
        }
        return 'user';
    }

    static convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    static calculateAge = (dob) => {
        const today = new Date();
        const birthdateDate = new Date(dob);

        let age = today.getFullYear() - birthdateDate.getFullYear();
        const monthDiff = today.getMonth() - birthdateDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdateDate.getDate())) {
            age--;
        }

        return age;
    };
}

export const getBaseUrl = () => {
    let env = process.env.REACT_APP_ENV || 'local';
    let apiGateWay = '';

    if (env === 'DEV') {
        apiGateWay = 'http://'
    } else {
        apiGateWay = 'https://b82d8jivm0.execute-api.us-east-1.amazonaws.com'
    }
    return apiGateWay;
}

export const apiResponseHandler = res => {
    if (res?.data?.status === 'success') {
        return res.data.data;
    } else {
        Swal.fire(res?.data?.message, '', 'error');
        throw new Error(res?.data?.message)
    }
}

export const getAllChildIds = (node) => {
    const tmpIds = [];
    const traverseRecursive = (n) => {
        tmpIds.push(n.id);
        if (Array.isArray(n.children)) {
            n.children.forEach(n => traverseRecursive(n));
        }
    }
    traverseRecursive(node)
    return tmpIds;
}

export const decodeKeyPass = (key, salt) => {
    let temp = key.substr(salt);
    return window.atob(temp);
}

export const hasSubscriptionExpired = () => {
    const userInfo = getUserInfo();
    let currentTime = new Date().getTime();
    if (!userInfo) {
        return true;
    }
    if (!userInfo.subscription_end || userInfo.subscription_end < currentTime) {
        return true;
    }
    return false;
}

export const applicationStatusSequence = ['Pending', 'Rejected', 'Cancelled', 'Non Practicing', 'Active', 'Practicing'];



export default Utils;