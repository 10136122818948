export const mobileCheck = () =>  {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

export const validateRequired = (requiredList, values) => {
    let errors = {};
    for(let req of requiredList) {
        try{
            if(!values[req]) {
                debugger;
                errors[req] = true;
            }
        }catch(err){
            console.log(err);
        }

    }
    debugger;
    return Object.keys(errors).length > 0 ? errors : false;
}



export const htmlEditorToolBar = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ]
};

export const getCourseObject = (data, cParent = 0) => {
    let courseObject = data.filter(al => al.parent === cParent);
    let extra = data.filter(al => al.parent !== cParent);

    const createCourseObject = (arr, path=[], parent = 0) => {
        let otherData = [];
        let filterData = extra.filter(f => {
            if (f.parent === parent) {
                f.path = [...path, f.id];
                return true;
            }
            otherData.push(f);
            return false;
        });
        extra = otherData;
        if (filterData.length > 0) {
            arr.children = filterData;
            arr.path = path;
            if (extra.length > 0) {
                arr.children.forEach(course => createCourseObject(course,[...path, course.id], course.id));
            }
        }
    }

    courseObject.forEach(course => createCourseObject(course,[course.id], course.id));
    return courseObject;
}

export const getCourseParents = (rawData, treeView, childId) => {
    let hierarchy = [];
    let categoryOption = [{
        type: 'Category',
        children: treeView
    }];
    let questionSelection = {};
    
    const setChildren = (tree , id) => {
        let node = tree.find(t => t.id === id);
        questionSelection = {
            ...questionSelection,
            [node.type]: node.id
        };
        if (node?.children) {
            categoryOption.push({
                type: node?.children?.[0]?.type,
                children: node.children
            });
            return node.children;
        }
    }
    const findParent = (cId)  => {
        let node = rawData.find(rd => rd.id === cId);
        if (node)
        hierarchy = [node, ...hierarchy];
        if (node.parent !== 0) {
            findParent(node.parent);
        }
    }


    findParent(childId);
    let tempParent = treeView;
    for (let h of hierarchy) {
        tempParent = setChildren(tempParent, h.id)
    }
    questionSelection['Category'] = hierarchy[0].id;
    
    return [questionSelection, categoryOption];
}

export const getCourseParentsTillSubscription = (rawData, treeView, childId) => {
    let hierarchy = [];
    let categoryOption = [{
        type: 'Category',
        children: treeView
    }];
    let questionSelection = {};
    
    const setChildren = (tree , id) => {
        let node = tree.find(t => t.id === id);
        questionSelection = {
            ...questionSelection,
            [node.type]: node.id
        };
        if (node?.children) {
            categoryOption.push({
                type: node?.children?.[0]?.type,
                children: node.children
            });
            return node.children;
        }
    }
    const findParent = (cId)  => {
        let node = rawData.find(rd => rd.id === cId);
        if (node)
        hierarchy = [node, ...hierarchy];
        if (node.parent !== 0) {
            findParent(node.parent);
        }
    }


    findParent(childId);
    let tempParent = treeView;
    for (let h of hierarchy) {
        tempParent = setChildren(tempParent, h.id)
    }
    questionSelection['Category'] = hierarchy[0].id;
    
    let subscriptionFound = false;
    categoryOption = categoryOption.filter(co => {
        let rValue = !subscriptionFound ? true : false;
        if (!subscriptionFound) {
            for(let s of co.children) {
                if (s.for_subscription) {
                    subscriptionFound = true;
                    break;
                }
            }
        }
        return rValue;
    })
    
    return [questionSelection, categoryOption];
}

export const getDocumentAccessUrl = (path) => {
    if (path.indexOf('digitaloceanspaces') === -1) {
        return `/uploads/${path}`;
    }
    return path;
}

export const secondToMinConvertor = (second) => {
    let min = second/60;
}


export const QUESTION_DIFFICULTY = [
    {value: 1, label: 'Easy'},
    {value: 2, label: 'Medium'},
    {value: 3, label: 'Hard'},
    {value: 4, label: 'Very Hard'},
];
export const selectionOption = [
    {value: 1, label: 'Single Select'},
    {value: 2, label: 'Multi Select'}
];
export const questionType = [
    {value: 1, label: 'Objective'},
    {value: 2, label: 'Subjective'}
]

export const subjectQuestionLength = [
    {value: 1, label: 'Very Short Question'},
    {value: 2, label: 'Short Question'},
    {value: 3, label: 'Long Question'}
]

export const formValidation = {
    // 1: ['reference1', 'reference1_document', 'reference2', 'reference2_document'],
    2: ['firstName', 'lastName', 'dob', 'gender', 'category', 'fathersName', 'nominee', 'relationNominee', 'maritalStatus','passport_photo', 'offense'],
    3: ['present_line1', 'present_line2', 'present_landmark', 'present_city', 'present_state', 'present_pin', 'present_country', 'permanent_line1', 'permanent_line2', 'permanent_landmark', 'permanent_city', 'permanent_state', 'permanent_pin', 'permanent_country'],
    4: ['hsc_board', 'hsc_school', 'hsc_location', 'hsc_passing', 'hsc_mark_secured', 'hsc_percentage', 'hsc_certificate', 'p2_board', 'p2_college', 'p2_location', 'p2_passing', 'p2_mark_secured', 'p2_percentage', 'p2_certificate', 'llb_board', 'graduation_board', 'graduation_college', 'graduation_location', 'graduation_passing', 'graduation_mark_secured', 'graduation_percentage', 'graduation_certificate', 'llb_college', 'llb_location', 'llb_passing', 'llb_mark_secured', 'llb_percentage', 'llb_practice', 'llb_certificate', 'llb_provisional']
}