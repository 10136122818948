import store from '../store';
import * as profileTypes from '../types/profiletypes';
import API from "../../services/api";
import Utils from '../../utils/utils';
import Axios from "axios";
const dispatch = store.dispatch;

export const pushNotification = async(payload) => {
    debugger;
    return API.put(Utils.getAPIDescription("push-notification"), payload).then(res => res.data);
}

export const fetchNotification = async(payload) => {
    return API.post(Utils.getAPIDescription("fetch-notification"), payload).then(res => res.data);
}

export const hideNotification = async(payload) => {
    return API.patch(Utils.getAPIDescription("hide-notification"), payload).then(res => res.data);
}

export const sendStatusEmail = async(payload) => {
    return API.post(Utils.getAPIDescription("send-status-email"), payload).then(res => res.data);
}