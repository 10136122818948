import { useEffect, Suspense } from 'react';
import './App.css';
import './assets/animate.min.css';
import { Routes, Route, useLocation } from 'react-router-dom';

import Navbar from './shared/components/navigation/navbar/navbar';
import Footer from './shared/components/footer/footer';
import LoadingComponent from './components/loading';
import { activeRoutes } from './routing';
import NetworkValidator from './components/offline';

import {useState } from 'react';

import './pages/user/home/home.scss';
import ForgetPassword from './components/login/forgetPassword';



function App() {
	const [path, setPath] = useState('');
	const [isAdminPage, setIsAdminPage] = useState();
	const location = useLocation();

	useEffect(() => {
		setIsAdminPage(location.pathname.includes('admin'));
	}, [location.path]);
	
	return (
		<div className='App'>
			<NetworkValidator />
			<div className={`${isAdminPage && 'flex-box'}`}>
				<Navbar />
				<Suspense fallback={<LoadingComponent />}>
					<Routes>
						{activeRoutes.map(r => 
							<Route path={r.path} element={<r.component />} />
						)}
					</Routes>
				</Suspense>
			</div>
			{!isAdminPage && <Footer />}
			<ForgetPassword />
		</div>
	);
}

export default App;
