
const local_instance = {
    local: {
        objective_questions: '/json/objectiveQuestions.json',
        mcq_answers: '/json/mcqAnswers.json',
        create_category: '/dev/services/createCategory',
        upload_image: '/dev/upload-img-to-s3',
        get_user_list: 'http://localhost:3001/api/getUserList',
        blogs_action: '/api/blogs',
        course_actions: 'http://localhost:3001/api/courses',
        question_actions: 'http://localhost:3001/api/questions',
        exam_actions: 'http://localhost:3001/api/exams',
        user_exam_question: 'http://localhost:3001/api/user_exam',
        upload_document: 'http://localhost:3001/api/course_document',
        user_study_materials: 'http://localhost:3001/api/user_study_materials',
        course_hierarchy_json: '/uploads/course-hierarchy.temp.json',
        admin_force_unsubscribe: 'http://localhost:3001/api/forceUnsubscribe',
        user_demo_exam: 'http://localhost:3001/api/user_exam/user_demo_exam',
        verify_email_id: 'http://localhost:3001/api/verifyEmail',
        update_user_password: 'http://localhost:3001/api/updatePasswordForUser'
    },
    dev: {
        category_list: '/dev/services/getCategories',
        objective_questions: '/json/objectiveQuestions.json',
        create_category: '/dev/services/createCategory',
        upload_image: '/dev/upload-img-to-s3',
        get_user_list: '/api/getUserList',
        blogs_action: '/api/blogs',
        course_actions: '/api/courses',
        question_actions: '/api/questions',
        exam_actions: '/api/exams',
        user_exam_question: '/api/user_exam',
        upload_document: '/api/course_document',
        user_study_materials: '/api/user_study_materials',
        course_hierarchy_json: '/uploads/course-hierarchy.temp.json',
        admin_force_unsubscribe: '/api/forceUnsubscribe',
        user_demo_exam: '/api/user_exam/user_demo_exam',
        verify_email_id: '/api/verifyEmail',
        update_user_password: '/api/updatePasswordForUser'
    }
};

const getUrlInstance = () => {
    if (window.location.href.indexOf('localhost') !== -1) {
        return local_instance['local'];
    }
    return local_instance['dev'];
}

export default getUrlInstance();