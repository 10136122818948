import React from 'react';


// Icons import
import DashboardIcon from '@mui/icons-material/Dashboard';
import PendingIcon from '@mui/icons-material/Pending';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

const Home = React.lazy(() => import('./pages/user/home/home'));
const PhotoGallery = React.lazy(() => import('./pages/user/photogallery/photogallery'));
const MemberGallery = React.lazy(() => import('./pages/user/membergallery/membergallery'));
const AboutUs = React.lazy(() => import('./pages/user/aboutus/'));
const ContactUs = React.lazy(() => import('./pages/user/contactus/'));

const Profile = React.lazy(() => import('./pages/user/profile/profile'));
const OurPartners = React.lazy(() => import('./pages/user/ourpartners/ourpartners'));
const Enrol = React.lazy(() => import('./pages/user/enrollment/enrollment'));
const EnrollNew = React.lazy(() => import('./pages/user/enrollNew/enrollNew'));
const PreviewApplication = React.lazy(() => import('./pages/user/previewapplication/previewapplication'));
const PreviewCertificate = React.lazy(() => import('./pages/user/previewcertificate/previewcertificate'));
const Search = React.lazy(() => import('./pages/user/search/search'));


// Admin pages import
const AdminLogin = React.lazy(() => import('./pages/admin/adminlogin/adminlogin'));
const Applicant = React.lazy(() => import('./pages/admin/applicant/applicant'));
const RejectedApplicant = React.lazy(() => import('./pages/admin/rejectedapplicant/rejectedapplicant'));
const PracticingLawers = React.lazy(() => import('./pages/admin/practicinglawers/practicinglawers'));
const NonPracticingLawers = React.lazy(() => import('./pages/admin/nonpracticinglawers/nonpracticinglawers'));
const Dashboard = React.lazy(() => import('./pages/admin/dashboard/dashboard'));
const AdminPhotoGallery = React.lazy(() => import('./pages/admin/adminphotogallery/adminphotogallery'));
const AdminMembers = React.lazy(() => import('./pages/admin/adminmembers/adminmembers'));



export const userRoutes = [{
    path: '/',
    text: 'Home',
    component: Home
},
{
    path: '/photogallery',
    text: 'Photo Gallery',
    component: PhotoGallery
},
{
    path: '/members',
    text: 'Member Gallery',
    component: MemberGallery
},
{
    path: '/aboutus',
    text: 'About Us',
    component: AboutUs
},
{
    path: '/partnerwithus',
    text: 'Our Partners',
    component: OurPartners
},

{
    path: '/contact',
    text: 'Contact Us',
    component: ContactUs
},
/*
{
    path: '/enrollment',
    text: 'Enrol',
    component: Enrol
},*/
{
    path: '/enrollment',
    text: 'Enroll',
    component: EnrollNew
},
{
    path: '/profile',
    text: 'Profile',
    component: Profile
},
{
    path: '/search',
    text: 'Search',
    component: Search
},
{
    path: '/previewapplication/:applicationId',
    text: 'Preview Application',
    component: PreviewApplication
},
{
    path: '/previewcertificate/:applicationId',
    text: 'Preview Certificate',
    component: PreviewCertificate
}

];

export const adminRoutes = [
    {
        path: '/admin/login',
        text: 'Login',
        isAdmin: true,
        hidden: true,
        component: AdminLogin
    },
    {
        path: '/admin',
        text: 'Dashboard',
        isAdmin: true,
        icon: DashboardIcon,
        component: Dashboard
    },
    /*
    {
        path: '/admin/applicant',
        text: 'Pending Application',
        isAdmin: true,
        icon: PendingIcon,
        component: Applicant
    },
    {
        path: '/admin/rejectedapplicant',
        text: 'Rejected Application',
        isAdmin: true,
        icon: ThumbDownIcon,
        component: RejectedApplicant
    },
    {
        path: '/admin/practicinglawers',
        text: 'Practicing Lawyers',
        isAdmin: true,
        icon: GroupAddIcon,
        component: PracticingLawers
    },
    {
        path: '/admin/nonpracticinglawers',
        text: 'Non Practicing Lawyers',
        isAdmin: true,
        icon: GroupRemoveIcon,
        component: NonPracticingLawers
    },*/
    {
        path: '/admin/adminphotogallery',
        text: 'Event Gallery',
        isAdmin: true,
        icon: PhotoLibraryIcon,
        component: AdminPhotoGallery
    },
    {
        path: '/admin/adminmembers',
        text: 'Members',
        isAdmin: true,
        icon: SupervisorAccountIcon,
        component: AdminMembers
    }
]

export const allRoutes = [...userRoutes, ...adminRoutes];

function getFlatComponent() {
    let temp = [];
    for (let path of allRoutes) {
        temp.push(path);
        if (path.children) {
            temp.push(path.children);
        }
    }
    return temp.flat(Infinity).filter(itm => itm.path !== undefined && itm.component !== undefined);
}
export const activeRoutes = getFlatComponent();
export default allRoutes;