import React, { useRef, useState, useEffect, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import "./notification.css";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { fetchNotification, hideNotification } from '../../redux/actions/notificationaction';
import useInfo from '../../customhooks/user/useinfo';
import { IconButton, DialogContent, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Notification(props) {
    const { open, close, videoPath } = props;
    const userInfo = useInfo();
    const [isNotification, setNotitification] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const wrapperRef = useRef(null);

    const navigate = useNavigate();
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setNotitification(false);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    }

    useEffect(() => {
        fetchAllNotification();
        let intervalId = setInterval(async () => { fetchAllNotification() }, 6000);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            clearInterval(intervalId);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const fetchAllNotification = async () => {
        let payload = {
            userid: userInfo.userid
        }
        await fetchNotification(payload).then((res) => {
            if (res?.status === 'success') {
                setNotifications(res.data || []);
            } else {
                console.log(res?.data?.message);
            }
        }).catch((err) => {
            console.log(err);

        });

    }

    const hideNotificationAlert = async (notid) => {
        let payload = {
            notid: notid
        }
        await hideNotification(payload).then((res) => {
            if (res?.status === 'success') {
                fetchAllNotification();
            } else {
                console.log(res?.data?.message);
            }
        }).catch((err) => {
            console.log(err);

        });

    }


    const showHideNotification = () => {
        if (!isNotification) {
            setNotitification(true);
        } else {
            setNotitification(false);
        }
        //hideNotificationAlert();
    }

    useEffect(() => {
        if (notifications.length > 0) {
            notifications.map((item) => {
                if (item.notificationType === 'new_user') {
                    setOpenDialog(true);
                } else {
                    setOpenDialog(false);
                }
            })
        }
    }, [notifications])

    const navigateToDetails = (link, notid) => {
        showHideNotification();
        navigate(link);
        hideNotificationAlert(notid);
    }


    return (
        <Box className="notification-module">
            <NotificationsIcon className="notification-icon" color='secondary' onClick={showHideNotification} />
            {
                notifications.length > 0 &&
                <>

                    <span className="not-count">
                        <Typography sx={{ color: "#ff0000", fontWeight: "bold" }}>{notifications.length}</Typography>
                    </span>

                    {
                        isNotification &&
                        <Box className="notification-component" ref={wrapperRef}>
                            <Box className="notification-content">
                                {
                                    React.Children.toArray(notifications.map((item, indx) => (
                                        <>
                                            <Box id={item.id}>
                                                {indx > 0 && <hr />}
                                                <Typography sx={{ fontSize: '13px' }}>
                                                    {item.notificationType === 'New' &&
                                                        <>
                                                            <span>
                                                                {
                                                                    item.notificationMsg + ' ' + item.firstName + ' ' + item.lastName
                                                                }
                                                            </span>
                                                            <span>, Please</span>&nbsp;
                                                            <u><span className="cursor-pointer" onClick={() => navigateToDetails(`${item.navigate}`, item.id)}>click here</span></u> to enroll
                                                        </>
                                                    }
                                                    {item.notificationType === 'Pending' &&
                                                        <>
                                                            <span>
                                                                {
                                                                    item.notificationMsg + ' ' + item.firstName + ' ' + item.lastName
                                                                }
                                                            </span>
                                                            <span>, Please check your application status here,</span>&nbsp;
                                                            <u><span className="cursor-pointer" onClick={() => navigateToDetails(`/previewapplication${item.navigate}`, item.id)}>click here</span></u> to check the details
                                                        </>
                                                    }
                                                    {item.notificationType === 'Cancelled' &&
                                                        <>
                                                            <span>
                                                                Your application was cancelled, Please
                                                            </span>
                                                            &nbsp;
                                                            <u><span className="cursor-pointer" onClick={() => navigateToDetails(`/previewapplication${item.navigate}`, item.id)}>click here</span></u> to check the details
                                                        </>
                                                    }
                                                    {item.notificationType === 'Rejected' &&
                                                        <>
                                                            <span>
                                                                Your application was rejected, Please
                                                            </span>
                                                            ,&nbsp;
                                                            <u><span className="cursor-pointer" onClick={() => navigateToDetails(`/previewapplication${item.navigate}`, item.id)}>click here</span></u> to check the details
                                                        </>
                                                    }
                                                    {item.notificationType === 'Active' &&
                                                        <>
                                                            <span>
                                                                Your application is approved, Please
                                                            </span>
                                                            ,&nbsp;
                                                            <u><span className="cursor-pointer" onClick={() => navigateToDetails(`/previewcertificate${item.navigate}`, item.id)}>click here</span></u> to download the ertificate
                                                        </>
                                                    }
                                                </Typography>
                                            </Box >

                                        </>
                                    )))
                                }
                            </Box>
                        </Box>
                    }

                </>
            }

            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="xl"
                onClose={handleClose}
                sx={{ zIndex: 99999 }}
                aria-describedby="alert-dialog-slide-description"
            >

                <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <Typography sx={{ textAlign: "center" }} variant='h4'>Subscribe to a course</Typography>
                    </div>
                    <IconButton onClick={handleClose}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex' }} alignItems={'center'} justifyContent={'center'}>
                        {/*openDialog && <CourseSubscription type='popup' closepopup={() => setOpenDialog(false)}/> */}
                    </Box>
                </DialogContent>
            </Dialog>

        </Box >
    );
}
