import * as searchTypes from '../types/searchtypes';
const initialSearchState = {
    searchResult: undefined,
    loading: false
}

export default function searchReducer(state = initialSearchState, action) {
    switch (action.type) {
        case searchTypes.ON_SEARCH_RESULT:
            return {
                ...state,
                searchResult: action.payload
            }
        case searchTypes.ON_SEARCH_FAILURE:
            return {
                ...state,
                searchResult: action.payload
            }

        default:
            return state;
    }
}