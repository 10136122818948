import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom"
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Card, Box
} from "@mui/material";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

import styled from '@emotion/styled';
import MenuIcon from "@mui/icons-material/Menu";
import useAuth from "../../../../customhooks/user/useauth";
import useInfo from "../../../../customhooks/user/useinfo";
import logo from "../../../../assets/logos/logo.png";
import Login from "../../../../components/login/login";
import Notification from "../../../../components/notification/notification";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import './navdrawer.css';
import { encryptStorage } from "../../../../utils/storage";


// Icons import
import CloseIcon from '@mui/icons-material/Close';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import RssFeedOutlinedIcon from '@mui/icons-material/RssFeedOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import ForumIcon from '@mui/icons-material/ForumOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import QuizIcon from '@mui/icons-material/QuizOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';

import PersonIcon from '@mui/icons-material/Person';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Person3Icon from '@mui/icons-material/Person3';
import PhoneIcon from '@mui/icons-material/Phone';
import BookIcon from '@mui/icons-material/Book';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

import Slide from '@mui/material/Slide';

const useStyles = styled(theme => ({
  root: {
    //Your styling rules
  },
  link: {
    textDecoration: "none",
    color: "blue",
    fontSize: "20px",
  },
  icon: {
    color: "white"
  }
}))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function NavDrawer() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();
  const [pathName, setPathName] = useState('/');
  const [fullName, setFullName] = useState('');
  const [isLogin, setLogin] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const userInfo = useInfo();
  const isLoginSignup = useSelector(state => state.login.isLoginSignup);
  const query = new URLSearchParams(location.search);


  const handleClose = () => {
    setLogin(false);
  }
  useEffect(() => {
    setOpenDrawer(false);
    setPathName(location.pathname);
    if (userInfo) {
      setFullName(userInfo.firstName + ' ' + userInfo.lastName);
    }
  }, [location.pathname])

  const openChatWithUs = () => {
    window.open('https://wa.me/919090951010')
  }

  const navigateMobile = (path) => {
    setOpenDrawer(false);
    navigate(path);
  }
  useEffect(() => {
    if (isLoginSignup) {
      setLogin(true);
    }
  }, [isLoginSignup]);
  useEffect(() => {
    let temp = query.get("isMobile") || localStorage.getItem("isMobile");
    if (temp) {
      localStorage.setItem('isMobile', true);
      setIsMobile(true);
    }
  }, [])


  return (
    <>
      {
        auth && <Box className={`${isMobile ? 'mobile-notification mobile-top' : 'mobile-notification'}`}>
          <Notification />
        </Box>
      }
      {/* <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        
      </Drawer> */}

      <Dialog
        open={openDrawer}
        TransitionComponent={Transition}
        fullScreen
      >
        <AppBar sx={{ position: 'relative', padding: '0px' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenDrawer(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Price Algo
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                if (!auth) {
                  setLogin(true);
                  setOpenDrawer(false);
                  return;
                }
                encryptStorage.clear();
                setOpenDrawer(false);
                navigateMobile('/');
              }}
              aria-label="close"
            >
              <PowerSettingsNewOutlinedIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent sx={{ px: 0 }}>
          <List className="animate__slideInRight animate__animated" sx={{ p: 0 }}>
            <>
              <ListItem>
                <ListItemButton onClick={() => navigateMobile('/')}>
                  <ListItemIcon><HomeOutlinedIcon /></ListItemIcon>
                  <ListItemText>Home</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton onClick={() => navigateMobile('/photogallery')}>
                  <ListItemIcon><PhotoLibraryIcon /></ListItemIcon>
                  <ListItemText>Event Gallery</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemButton onClick={() => navigateMobile('/about')}>
                  <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
                  <ListItemText>About</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton onClick={() => navigateMobile('/members')}>
                  <ListItemIcon><PersonIcon /></ListItemIcon>
                  <ListItemText>Members</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton onClick={() => navigateMobile('/contact')}>
                  <ListItemIcon><PhoneIcon /></ListItemIcon>
                  <ListItemText>Contact</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemButton onClick={() => navigateMobile('/fee-structure')}>
                  <ListItemIcon><BookIcon /></ListItemIcon>
                  <ListItemText>Blog</ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
            {
              !auth ?
                <>
                  <ListItem>
                    <ListItemText>
                      <ListItemButton onClick={() => { setLogin(true); setOpenDrawer(false); }}>
                        <ListItemIcon><PowerSettingsNewOutlinedIcon /></ListItemIcon>
                        <ListItemText>Login/Signup</ListItemText>
                      </ListItemButton>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </> :
                <>
                  <ListItem>
                    <ListItemButton onClick={() => navigateMobile('/search')}>
                      <ListItemIcon><PersonSearchIcon /></ListItemIcon>
                      <ListItemText>Search</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemButton onClick={() => navigateMobile('/forum')}>
                      <ListItemIcon><CampaignOutlinedIcon /></ListItemIcon>
                      <ListItemText>Forum</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemButton onClick={() => navigateMobile('/profile')}>
                      <ListItemIcon><AccountCircleOutlinedIcon /></ListItemIcon>
                      <ListItemText>
                        Profile<span style={{ fontSize: '11px' }}>{' (' + fullName + ') '}</span>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem onClick={openChatWithUs}>
                    <ListItemText>
                      <ListItemButton>
                        <ListItemIcon><ForumIcon /></ListItemIcon>
                        Chat With Us
                      </ListItemButton>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem onClick={() => { encryptStorage.clear(); setOpenDrawer(false); }}>
                    <ListItemButton onClick={() => navigateMobile('/')}>
                      <ListItemIcon><PowerSettingsNewOutlinedIcon /></ListItemIcon>
                      <ListItemText>Logout</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
            }
          </List >
        </DialogContent >
      </Dialog >


      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', width: 'calc(100vw - 20px)' }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="img-logo" style={{ height: "30px" }} />
        </Box>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.icon}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Login open={isLogin} close={handleClose} />
    </>
  );
}

export default NavDrawer;