import * as vendorTypes from '../types/vendortypes';
const initialVendorState = {
    vendorTypes: undefined,
    loading: false
}

export default function vendorReducer(state = initialVendorState, action) {
    switch (action.type) {
        case vendorTypes.ON_VENDOR_TYPES_LOAD_SUCCESS:
            return {
                ...state,
                vendorTypes: action.payload?.body
            }
        case vendorTypes.ON_VENDOR_TYPES_LOAD_FAILURE:
            return {
                ...state,
                vendorTypes: action.payload
            }

        default:
            return state;
    }
}