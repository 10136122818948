import { applyMiddleware, compose, legacy_createStore as createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import loginReducer from './reducers/loginreducer';
import signupReducer from './reducers/signupreducer';
import categoryReducer from './reducers/categoryreducer';
import vendorReducer from './reducers/vendorreducer';
import profileReducer from './reducers/profilereducer';
import mainReducer from './reducers/main.reducer';
import searchReducer from './reducers/searchreducer';

const combinedReducer = combineReducers({
    signup: signupReducer,
    login: loginReducer,
    category: categoryReducer,
    vendor: vendorReducer,
    profile: profileReducer,
    main: mainReducer,
    search: searchReducer
})

function getStore(reducers){
    const composeEnhancers = 
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducers,
        composeEnhancers(applyMiddleware (thunk))
    );
    return store;
}

const store = getStore(combinedReducer);
export default store;