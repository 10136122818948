import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';


const query = new URL(window.location.href);
if (query.searchParams.get("isMobile")) {
  localStorage.setItem('isMobile', true);
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: '#0CABB8',
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: '#BEBEBF', // Customize label color when focused
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#BEBEBF', // Customize underline color
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#BEBEBF', // Customize outline color when focused
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides:{
        root:{
          color: '#BEBEBF',
          '&.Mui-checked':{
            color: '#BEBEBF',
          }
        }
      }
    },
    MuiButton: {
      styleOverrides:{
        root:{
          color: '#fff',
          textTransform: 'none'
        }
      }
    },
    MuiCardContent: {
      styleOverrides:{
        root:{
          padding: '16px !important',
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
